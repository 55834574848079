import * as React from "react"
import { graphql } from 'gatsby';

import Image from 'components/image';
import Layout from 'components/layout';
import Mission from 'components/mission';
import Pager from 'components/pager';
import PostCard from 'components/postCard';
import Seo from 'components/seo';

import masthead from 'images/masthead.jpg';
import * as style from 'styles/post-listing.module.scss';

const ArticlesPage = (props) => {
	let page = null;
	if (props.data.allContentPage.edges.length)page = props.data.allContentPage.edges[0].node;
	const { edges: nodes } = props.data.allBlogPost;
	const posts = nodes.map(({node}) => node);
	const { pageInfo } = props.data.allBlogPost;
	const { pageContext } = props;
	const pattern = ['/articles/', '/articles/page-$page/'];
	return (
		<Layout>
			<Image className={style.masthead} src={masthead} />
			{page && <Seo title={page.pageTitle} description={page.pageDescription} meta={page.meta} /> }
			<section className={style.articles}>
				<div className="grid-container">
					<div className="grid-x grid-margin-x">
						<div className="cell small-12 medium-9">
							<div className={style.headline}>Explore Inclusion & Diversity Topics</div>
						</div>
					</div>
				</div>

				<div className="grid-container">
					<div className="grid-x grid-margin-x small-up-1 medium-up-2 large-up-3">
						{posts.map(post => <div className={`cell ${style.card}`}><PostCard {...post} /></div>)}
					</div>
				</div>

				{ pageInfo.totalCount > pageInfo.perPage &&
					<div className="grid-container">
						<div className="grid-x grid-margin-x">
							<div className="cell small-12">
								<div className={style.pager}>
									<Pager page={pageContext.page} pages={pageContext.pages} pattern={pattern} scale={10} pageSize={pageInfo.perPage} total={pageInfo.totalCount} />
								</div>
							</div>
						</div>
					</div>
				}
			</section>

			<Mission />
		</Layout>
	)
}

export default ArticlesPage

export const query = graphql`
	query ($skip: Int!, $limit: Int!) {
	  allContentPage(filter: {uri: {eq: "/articles/"}, deleted: {eq: false}, archived: {eq: false}, approved: {eq: true}}) {
	    edges {
	      node {
	        id
			uri
	        content {
	          main
	        }
	        meta
			pageTitle
			pageDescription
	      }
	    }
	  }
	  allBlogPost(
	    limit: $limit
	    skip: $skip
	    sort: {fields: showDate, order: DESC}
	  ) {
	    edges {
	      node {
	      	id
	        title
	        intro
	        permalink
	        photo
	      }
	    }
	    pageInfo {
	      hasNextPage
	      perPage
	      currentPage
	      totalCount
	    }
	  }
	}
`