import React from 'react'
import { buildURL } from 'react-imgix';

const Image = (props) => {
	const domain = process.env.IMGIX_DOMAIN;
	let imagePath = (props.src ? props.src.replace(/^\//, '') : 'images/placeholder.jpg');
	if (imagePath.indexOf('/') === -1)imagePath = `images/${imagePath}`;
	imagePath = `${domain}/${imagePath}`;
	const alt = props.alt||'';
	const className = (props.className||'').split(' ');
	const sizes = props.sizes||[
		480,
		960,
		1000,
	];
	let width = props.width||1920;
	/*
	const lowQuality = buildURL(imagePath, {
		w: width*.05,
		auto: 'compress,format',
	});
	*/
	const fallback = buildURL(imagePath, {
		w: width,
		auto: 'compress',
	});
	const srcset = [];
	sizes.forEach(function(size){
		srcset.push(`${buildURL(imagePath, {
			w: size,
			auto: 'compress,format',
		})} ${size}w`);
	});
	const srcsetWebP = [];
	sizes.forEach(function(size){
		srcsetWebP.push(`${buildURL(imagePath, {
			w: size,
			auto: 'compress',
			format: 'webp',
		})} ${size}w`);
	});
	const sizesAttribute = `(max-width: ${sizes[sizes.length-1]}px) 100vw, ${sizes[sizes.length-1]}px`;

	// className.push('lazyload');

	return (
		<picture>
			<source
				type="image/webp"
				srcSet={srcsetWebP.join(', ')}
				sizes={sizesAttribute}
			/>
			<source
				srcSet={srcset.join(', ')}
				sizes={sizesAttribute}
			/>
			<img
				className={className.join(' ')}
				alt={alt}
				data-src={fallback}
				data-srcset={srcset.join(', ')}
				data-sizes={sizesAttribute}
			 />
		</picture>
	)
}

export default Image